import React from 'react'
import Paper from '../components/Paper'
import { Parallax } from 'react-parallax'
import { Element, Link } from 'react-scroll'
import { Container, Row, Col } from 'react-bootstrap'
import { ReactComponent as Facebook } from '../icons/facebook.svg'
import { ReactComponent as Instagram } from '../icons/instagram.svg'
import config from '../config'
const ContactPage = (props) => {
  const { contactPerson, aboutAddressLine1, aboutAddressLine2, aboutTelephone, contactEmail, contactTitle, contactBackground, contactLatLong, facebookAddress, instagramAddress } = props.storage || {}
  return <div>
    <Element id='contact'>
      <Parallax
        bgImage={`${config.API_URL}/file/${contactBackground}`}
        strength={400}
      >
        <div className='paper-header' style={{ height: '50vh' }}>
          <div className='d-flex flex-column justify-content-center align-content-center h-100 text-center'>
            <Link to='contact-content' smooth>{contactTitle}</Link>
          </div>
        </div>
      </Parallax>
      <Element id='contact-content'>
        <Paper>
          <Container >
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <div className='text-item'>
                  <div className='text-title'>{contactPerson}</div>
                  <div className='text-description'>
                    {aboutAddressLine1}<br />
                    {aboutAddressLine2}<br />
                    <br />
                    <a href={`tel:${aboutTelephone}`}>{aboutTelephone}</a><br />
                    <a href={`mailto:${contactEmail}`}>{contactEmail}</a><br />
                  </div>
                </div>
                <div className='text-item'>
                  <a href={facebookAddress} target='_blank'><Facebook style={{ height: 60, marginRight: 10 }} /></a>
                  <a href={instagramAddress} target='_blank'><Instagram style={{ height: 60 }} /></a>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={8} >
                <iframe
                  style={{ width: '100%', height: 280, border: 0 }}
                  frameBorder='0'
                  src={`https://www.google.com/maps/embed/v1/view?zoom=19&center=${contactLatLong || '59.355961,18.134163'}&key=AIzaSyBYv0r67hQg-zKJTAHd6BthH7h1vpFpBK0`}
                  allowFullScreen />
              </Col>
            </Row>
          </Container>
        </Paper>
      </Element>
    </Element>
  </div>
}
export default ContactPage
