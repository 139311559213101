import React from 'react'
export default function Paper (props) {
  return (
    <div className='paper'>
      <div className='paper-content-wrapper'>
        <div className='paper-content'>
          {props.children}
        </div>
      </div>
    </div>
  )
}
