import React, { useState, useEffect } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
import config from '../config'
import { useLocalStorage } from '../hooks'

const LoginPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [token, setToken] = useLocalStorage('token', '')
  const history = useHistory()

  const setError = (msg) => {
    setErrorText(msg)
    if (msg) {
      setTimeout(() => {
        setErrorText('')
      }, 10000)
    }
  }

  const login = async () => {
    setLoading(true)
    setError('')
    try {
      const res = await Axios.post(config.API_URL + '/login', { username, password })
      const { data } = res || {}
      const { success, msg, token } = data || {}
      if (success && token) {
        setToken(token)
        history.push('/admin')
      } else {
        setError(msg)
      }
    } catch (e) {
      const { msg } = e.response.data || {}
      setError(msg || 'Unknown error')
    }
    setLoading(false)
  }

  useEffect(() => {

  })

  return <div className='d-flex flex-row justify-content-center align-items-center'>
    <Card style={{ maxWidth: 500, minWidth: 240, marginTop: '10%' }}>
      <Card.Body>
        <Card.Title>Login</Card.Title>
        <Form >
          {errorText && <Form.Group>
            <Alert variant='danger'>{errorText}</Alert>
          </Form.Group>}
          <Form.Group>
            <Form.Control placeholder={'username'} value={username} onChange={(e) => {
              setUsername(e.target.value)
            }}></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Control type='password' placeholder={'password'} value={password} onChange={(e) => {
              setPassword(e.target.value)
            }}></Form.Control>
          </Form.Group>
          <Form.Group>
            <Button onClick={login} disabled={loading}>
              {loading ? 'Loading...' : 'Login'}
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  </div>
}
export default LoginPage
