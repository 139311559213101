import React, { useState, useEffect } from 'react'
import { Form, Button, InputGroup, Tabs, Tab, Badge, Card, TabContent } from 'react-bootstrap'
import config from '../config'
import axios from 'axios'
import { ReactComponent as TrashIcon } from '../icons/trash.svg'
import { ReactComponent as PlusIcon } from '../icons/plus.svg'
import { useLocalStorage } from '../hooks'

const FormEdit = (props) => {
  const { element, storage = {}, parent, fetchStorage } = props
  const { name, type, elements = [], title } = element
  const defaultValue = type === 'array' ? [] : ''
  const oldValue = storage[name] || defaultValue
  const [value, setValue] = useState(defaultValue)
  const [files, setFiles] = useState({})
  const [values, setValues] = useState({})
  const [uploadRequired, setUploadRequired] = useState(false)
  const [activeKey, setActiveKey] = useState(0)
  const [isChanged, setChanged] = useState(false)
  const [token] = useLocalStorage('token', '')

  const fieldName = (parent ? `${parent}.` : '') + name
  const onChange = (e) => {
    const { name, value, files: fs } = e.target
    if (fs) {
      const { name: fileName = 'File Selected' } = fs[0] || {}
      const newFiles = { }
      Object.assign(newFiles, files)
      newFiles[name] = fs
      setFiles(newFiles)
      // console.log('set', { name, newFiles })
      setValue(fileName)
    } else {
      const newValues = {}
      Object.assign(newValues, values)
      newValues[name] = value
      setValues(newValues)
      // console.log('set', { name: newValues })
      setValue(value)
    }

    setChanged(true)
  }

  const upload = async () => {
    // console.log('upload', { values, files })
    const data = new window.FormData()
    for (const name in files) {
      const fileList = files[name]
      try {
        for (const file of fileList) {
          data.append(name, file)
        }
      } catch (e) {
        data.append(name, fileList)
      }
    }
    for (const name in values) {
      data.append(name, JSON.stringify(values[name]))
    }
    const res = await axios.post(`${config.API_URL}/upload`, data, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
    if (res && res.data && res.data.success) {
      setUploadRequired(false)
      setChanged(false)
      fetchStorage && fetchStorage()
    }
  }

  useEffect(() => {
    if (storage && !isChanged && type !== 'image') {
      setValue(storage[name] || defaultValue)
    }
    if (uploadRequired) {
      upload()
    }
  })

  if (type === 'image') {
    return <div>
      <Form.Group>
        <Form.Label>{title}</Form.Label>
        <InputGroup>
          <Form.File
            name={fieldName} accept='image/*' onChange={onChange} custom label={value || 'Browse File'}
          />
          {isChanged && <InputGroup.Append>
            <Button onClick={upload}>Upload</Button>
          </InputGroup.Append>}
        </InputGroup>
        {oldValue && <Form.Text>
          Uploaded Image <a href={`${config.API_URL}/file/${oldValue}`}>VIEW</a>
        </Form.Text>}
      </Form.Group>
    </div>
  }
  if (type === 'text') {
    return <div>
      <Form.Group>
        <Form.Label>{title}</Form.Label>
        <InputGroup>
          <Form.Control name={fieldName} onChange={onChange} placeholder={oldValue} value={value} />
          {isChanged && <InputGroup.Append>
            <Button onClick={upload}>Edit</Button>
          </InputGroup.Append>}
        </InputGroup>
      </Form.Group>
    </div>
  }
  if (type === 'textarea') {
    return <div>
      <Form.Group>
        <Form.Label>{title}</Form.Label>
        <InputGroup>
          <Form.Control as='textarea' name={fieldName} onChange={onChange} placeholder={oldValue} value={value} />
          {isChanged && <InputGroup.Append>
            <Button onClick={upload}>Edit</Button>
          </InputGroup.Append>}
        </InputGroup>
      </Form.Group>
    </div>
  }
  if (type === 'array' && elements) {
    return <div>
      <Form.Group>
        <Form.Label>{title}</Form.Label>
        <Card>
          <Card.Body>
            <Tabs transition={false} variant='pills' activeKey={activeKey} onSelect={(key) => {
              if (parseInt(key, 10) === oldValue.length) {
                const newValue = [...oldValue]
                newValue.push({})
                onChange({ target: { name: fieldName, value: newValue } })
                setUploadRequired(true)
              }
              setActiveKey(key)
            }}>
              {oldValue && oldValue.map((child, i) => {
                const subFieldName = `${fieldName}.${i}`
                const subTitle = <span>
                  {title}{' '}
                  <Badge variant='secondary'>{i + 1}</Badge>{' '}
                  <TrashIcon
                    onClick={() => {
                      if (window.confirm(`Confirm to delete."${title} ${i + 1}" `)) {
                        const newValue = [...oldValue]
                        newValue.splice(i, 1)
                        onChange({ target: { name: fieldName, value: newValue } })
                        setUploadRequired(true)
                      }
                    }} />
                </span>
                return <Tab eventKey={i} title={subTitle} key={i}>
                  <TabContent>
                    {elements.map((element, j) => {
                      return <FormEdit key={j} element={element} storage={child} parent={subFieldName} fetchStorage={fetchStorage} />
                    })}
                  </TabContent>
                </Tab>
              })}
              <Tab
                title={<PlusIcon />}
                eventKey={oldValue.length}
              />
            </Tabs>
          </Card.Body>
        </Card>
      </Form.Group>
    </div>
  }
}
export default FormEdit
