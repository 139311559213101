import React from 'react'
import Paper from '../components/Paper'
import { Parallax } from 'react-parallax'
import { Element, Link } from 'react-scroll'
import Images from '../components/Images'
import config from '../config'

const GalleryPage = (props) => {
  const { galleryImages, galleryTitle, galleryBackground } = props.storage || {}
  return <div>
    <Element id='gallery'>
      <Parallax
        bgImage={`${config.API_URL}/file/${galleryBackground}`}
        strength={400}
      >
        <div className='paper-header' style={{ height: '50vh' }}>
          <div className='d-flex flex-column justify-content-center align-content-center h-100 text-center'>
            <Link to='gallery-content' smooth>{galleryTitle}</Link>
          </div>
        </div>
      </Parallax>
      <Element id='gallery-content'>
        <Paper>
          <Images images={galleryImages || []} />
        </Paper>
      </Element>
    </Element>
  </div>
}
export default GalleryPage
