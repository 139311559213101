
import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Home from './Home'
import AdminPage from './pages/AdminPage'
import LoginPage from './pages/LoginPage'
import PasswordPage from './pages/PasswordPage'

const App = () => {
  return <Router>
    <Switch>
      <Route path='/admin'>
        <AdminPage />
      </Route>
      <Route path='/login'>
        <LoginPage />
      </Route>
      <Route path='/passwd'>
        <PasswordPage />
      </Route>
      <Route path='/preview'>
        <Home preview />
      </Route>
      <Route path='/'>
        <Home />
      </Route>
    </Switch>
  </Router>
}

export default App
