import React from 'react'
import Paper from '../components/Paper'
import { Parallax } from 'react-parallax'
import { Element, Link } from 'react-scroll'
import { Container, Row, Col } from 'react-bootstrap'
import config from '../config'

const ServicePage = (props) => {
  const { serviceBackground, serviceTitle, serviceDescription } = props.storage || {}
  return <div>
    <Element id='service'>
      <Parallax
        bgImage={`${config.API_URL}/file/${serviceBackground}`}
        strength={400}
      >
        <div className='paper-header' style={{ height: '50vh' }}>
          <div className='d-flex flex-column justify-content-center align-content-center h-100 text-center'>
            <Link to='service-content' smooth>{serviceTitle}</Link>
          </div>
        </div>
      </Parallax>
      <Element id='service-content'>
        <Paper>
          <Container >
            <Row>
              <Col>
                <div className='text-item text-center'>
                  <div className='text-title'>{serviceTitle}</div>
                  <div className='text-description text-justify'>{serviceDescription}</div>
                </div>
              </Col>
            </Row>
          </Container>
        </Paper>
      </Element>
    </Element>
  </div>
}
export default ServicePage
