import React, { useState } from 'react'
import { Link } from 'react-scroll'
import { Container, Row, Col } from 'react-bootstrap'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const Navbar = (props) => {
  const [navStyle, setNavStyle] = useState({ visibility: 'visible' })
  const { siteTitle, menuTitle, serviceTitle, galleryTitle, contactTitle, orderNavbarTitle, orderUrl } = props.storage || {}
  useScrollPosition(function ({ prevPos, currPos }) {
    const hasVisible = navStyle.visibility === 'visible'
    const isVisible = currPos.y > prevPos.y
    if (isVisible !== hasVisible) {
      const newStyle = {
        visibility: isVisible ? 'visible' : 'hidden',
        transition: `all 200ms ${isVisible ? 'ease-in' : 'ease-out'}`,
        transform: isVisible ? 'none' : 'translate(0, -100%)'
      }
      setNavStyle(newStyle)
    }
  })
  return <div className='navbar-items d-flex justify-content-center' style={navStyle}>
    <Container>
      <div className='navbar-content'>
        <Row xs={1} sm={1} md={6}>
          <Col className='navbar-item'>
            <Link to='about' smooth spy hashSpy>{siteTitle || 'Suuni Sushi'}</Link>
          </Col>
          <Col className='navbar-item'>
            <Link to='menu' smooth spy hashSpy>{menuTitle || 'Menu' }</Link>
          </Col>
          <Col className='navbar-item'>
            <Link to='service' smooth spy hashSpy>{serviceTitle || 'Service'}</Link>
          </Col>
          <Col className='navbar-item'>
            <Link to='gallery' smooth spy hashSpy>{galleryTitle || 'Gallery'}</Link>
          </Col>
          <Col className='navbar-item'>
            <Link to='contact' smooth spy hashSpy>{contactTitle || 'Contact'}</Link>
          </Col>
          {!!orderUrl && <Col className='navbar-item'>
            <Link onClick={() => {
              window.location.href = orderUrl
            }}>{orderNavbarTitle || 'Beställ Online'}</Link>
          </Col>}
        </Row>
      </div>
    </Container>
  </div>
}
export default Navbar
