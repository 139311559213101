import React from 'react'
import Paper from '../components/Paper'
import { Parallax } from 'react-parallax'
import { Element, Link } from 'react-scroll'
import { Container, Row, Col } from 'react-bootstrap'
import { ReactComponent as DownArrow } from '../icons/down-arrow.svg'
import config from '../config'
const AboutPage = (props) => {
  const {
    aboutBackground,
    aboutTelephone, timeTables, aboutAddressLine1, aboutAddressLine2,
    aboutTitle, aboutDescription,
    orderTitle, orderUrl
  } = props.storage || {}
  const backgroundImage = `${config.API_URL}/file/${aboutBackground}`
  return <div>
    <Element id='about'>
      <Parallax
        bgImage={backgroundImage}
        strength={400}
      >
        <div className='paper-header' style={{ height: '100vh' }}>
          <div className='d-flex justify-content-center' style={{ position: 'absolute', bottom: 100, width: '100%' }}>
            {(orderUrl && orderTitle)
              ? <a className='order-button' href={orderUrl}>
                {orderTitle}
              </a>
              : <a className='call-button' href={`tel:${aboutTelephone}`}>
                Call Now
              </a>}
          </div>
          <div className='d-flex justify-content-center' style={{ position: 'absolute', bottom: 30, width: '100%', fontSize: 12 }}>
            <Link to='about-content' smooth className='scroll-button'>
              <span className='scroll-icon'>
                <DownArrow style={{ height: 40, fill: 'white' }} />
              </span>
              <span className='scroll-text'>SCROLL DOWN</span>
            </Link>
          </div>
        </div>
      </Parallax>
      <Element id='about-content'>
        <Paper>
          <Container >
            {aboutTitle && aboutDescription && <Row>
              <Col>
                <div className='text-item text-center'>
                  <div className='text-title'>{aboutTitle}</div>
                  <div className='text-description'>{aboutDescription}</div>
                </div>
              </Col>
            </Row>}
            {aboutAddressLine1 && aboutAddressLine2 && <Row>
              <Col>
                <div className='text-item text-center'>
                  <div className='text-title'>{aboutAddressLine1}</div>
                  <div className='text-description'>{aboutAddressLine2}</div>
                </div>
              </Col>
            </Row>}
            {timeTables && <Row>
              <Col>
                <Row xs={1} sm={2} md={3} lg={3} xl={3} >
                  {timeTables.map((timeTable, i) => {
                    return <Col key={i}>
                      <div className='text-item text-center'>
                        <div className='text-title'>{timeTable.title}</div>
                        <div className='text-description'>{timeTable.description}</div>
                      </div>
                    </Col>
                  })}
                </Row>
              </Col>
            </Row>}
          </Container>
        </Paper>
      </Element>
    </Element>
  </div>
}
export default AboutPage
