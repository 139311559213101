/* global FormData */

import React, { useState, useEffect } from 'react'
import { Form, Jumbotron, Button, Alert, ButtonGroup } from 'react-bootstrap'
import axios from 'axios'
import config from '../config'
import FormEdit from '../components/FormEdit'
import Card from 'react-bootstrap/Card'
import { useHistory } from 'react-router-dom'
import { useLocalStorage, useAuth } from '../hooks'

const AdminPage = () => {
  const [storageObj, setStorageObj] = useState({})
  const { success, storage = {} } = storageObj
  const { publishedAt = 0, draftAt = 1 } = storage || {}
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [token, setToken] = useLocalStorage('token', '')
  const user = useAuth()
  const fetchStorage = async () => {
    setLoading(true)
    const res = await axios.get(`${config.API_URL}/draft`, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
    setStorageObj(res.data)
    console.log(res.data)
    setLoading(false)
  }

  const logout = async () => {
    setToken('')
    history.push('/login')
  }

  const passwd = async () => {
    history.push('/passwd')
  }

  useEffect(() => {
    if (user && !success) {
      fetchStorage()
    }
  })

  const publish = async () => {
    if (window.confirm('Publish your changes')) {
      try {
        setLoading(true)
        const data = new FormData()
        data.append('submit', 'true')
        const res = await axios.post(`${config.API_URL}/publish`, data, {
          headers: { 'Authorization': `Bearer ${token}` }
        })
        if (res.data) {
          fetchStorage()
        }
      } catch (e) {
        console.error(e)
      }
      setLoading(false)
    }
  }
  if (user) {
    return <div>
      <Jumbotron>
        <Alert variant='primary'>Hello, <a href='#' onClick={logout}>Logout</a> <span>{user}</span></Alert>
        <Form style={{ paddingTop: 40, paddingBottom: 40 }}>
          <Card>
            <Card.Body>
              {config.ELEMENTS.map((element, i) => {
                return <FormEdit key={i} element={element} storage={storage} fetchStorage={fetchStorage} />
              })}
              <Form.Group>
                <ButtonGroup>
                  {draftAt > publishedAt && <Button onClick={publish} disabled={loading}>{loading ? 'Loading...' : 'Publish'}</Button>}
                  {draftAt > publishedAt && <Button as={'a'} variant='secondary' href='/preview' target='_blank'>Preview</Button>}
                </ButtonGroup>
                <Form.Text>Last saved at {new Date(draftAt).toString()}</Form.Text>
                <Form.Text>Last published at {new Date(publishedAt).toString()}</Form.Text>
              </Form.Group>
            </Card.Body>
          </Card>
        </Form>
        <div className={'text-center'}><a href='#' onClick={passwd}>Change Password Here</a></div>
      </Jumbotron>
    </div>
  }
  return <Jumbotron>
    <Alert variant='warning'>Hello, <a href='#' onClick={logout}>Login</a> required</Alert>
  </Jumbotron>
}
export default AdminPage
