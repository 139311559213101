
import React, { useState } from 'react'
import { Container, Row, Col, Modal, Image } from 'react-bootstrap'
import config from '../config'
import { useWindowDimensions } from '../hooks'
// import ReactImageVideoLightbox from 'react-image-video-lightbox'
const Images = (props) => {
  const { images } = props
  const { height } = useWindowDimensions()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isVisibleLightbox, setVisibleLightbox] = useState(false)
  const onClick = (image, i) => {
    setSelectedIndex(i)
    setVisibleLightbox(true)
  }
  const selectedImage = images[selectedIndex]
  return (
    <Container>
      {isVisibleLightbox && selectedImage && <Modal
        show={isVisibleLightbox}
        onHide={() => { setVisibleLightbox(false) }}
        dialogClassName='modal-90w'
        centered
        size='xl'
        animation={false}
                                             >
        <div className='d-flex flex-column justify-content-center align-items-center'>
          {selectedImage.image && <Image src={config.API_URL + '/file/' + selectedImage.image} fluid style={{ width: '100%' }} loading='eager' />}
          {selectedImage.video && <iframe style={{ width: '100%', height: height * 0.8 }} src={selectedImage.video} frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen />}
        </div>
      </Modal>}
      <Row xs={12} className='d-flex justify-content-start'>
        {images && images.map((image, i) => {
          const src = image.thumb || image.image
          return (
            <Col key={i} xs={6} sm={6} md={3} lg={3} className='p-0'>
              <div className='menu-item' style={{ position: 'relative' }} onClick={() => { onClick(image, i) }}>
                {image.title && <div className='menu-name text-center d-flex flex-column justify-content-center align-content-center' style={{ position: 'absolute', top: 0, height: '100%', width: '100%' }}>
                  <div className='menu-title'>{image.title}</div>
                  {image.subTitle && <div className='menu-subtitle'>{image.subTitle}</div>}
                                </div>}
                <div
                  className='menu-image' style={{
                    backgroundImage: `url(${config.API_URL}/file/${src})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center'
                  }}
                />
              </div>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

export default Images
