import React, { useEffect, useState } from 'react'
import Navbar from './components/Navbar'
import AboutPage from './pages/AboutPage'
import MenuPage from './pages/MenuPage'
import ServicePage from './pages/ServicePage'
import GalleryPage from './pages/GalleryPage'
import ContactPage from './pages/ContactPage'
import Axios from 'axios'
import config from './config'
import { useLocalStorage } from './hooks'
import { Modal } from 'react-bootstrap'

const Home = (props) => {
  const { preview } = props
  const [storageObj, setStorageObj] = useState({})
  const [token] = useLocalStorage('token', '')
  const { success, storage } = storageObj
  const { announcement = '', announcementTitle } = storage ?? {}
  const hasAnnouncement = announcement.trim() !== ''
  const [isShow, setShow] = useState(true)
  const fetchStorage = async () => {
    if (preview && token) {
      const res = await Axios.get(`${config.API_URL}/draft`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      setStorageObj(res.data)
    } else {
      const res = await Axios.get(`${config.API_URL}/storage`)
      setStorageObj(res.data)
    }
  }

  useEffect(() => {
    if (!success) {
      fetchStorage()
    }
  })

  return (
    <div className='page'>
      <Modal show={isShow && hasAnnouncement} onHide={() => setShow(false)} className='modal-announcement'>
        <Modal.Header>
          {announcementTitle ?? 'Announcement'}
        </Modal.Header>
        <Modal.Body className=''>
          {announcement}
        </Modal.Body>
      </Modal>
      <Navbar storage={storage} />
      <AboutPage storage={storage} />
      <MenuPage storage={storage} />
      <ServicePage storage={storage} />
      <GalleryPage storage={storage} />
      <ContactPage storage={storage} />
    </div>
  )
}
export default Home
