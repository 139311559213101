const STRUCTURE = {
  announcementTitle: { title: 'Announcement Title', type: 'text' },
  announcement: { title: 'Announcement', type: 'textarea' },
  siteTitle: { title: 'Site Title', type: 'text' },
  aboutBackground: { title: 'Home Background Image', type: 'image' },
  aboutTelephone: { title: 'Telephone', type: 'text' },
  aboutAddressLine1: { title: 'Address Line 1', type: 'text' },
  aboutAddressLine2: { title: 'Address Line 2', type: 'text' },
  aboutTitle: { title: 'About Title', type: 'text' },
  aboutDescription: { title: 'About Description', type: 'textarea' },
  orderTitle: { title: 'Order Title', type: 'text' },
  orderUrl: { title: 'Order URL', type: 'text' },
  orderNavbarTitle: { title: 'Order Navbar Title', type: 'text' },
  timeTables: {
    title: 'Time Table',
    type: 'array',
    elements: {
      title: { title: 'Days', type: 'text' },
      description: { title: 'Hours', type: 'text' }
    }
  },
  menuBackground: { title: 'Menu Background Image', type: 'image' },
  menuTitle: { title: 'Menu Title', type: 'text' },
  menuImages: {
    title: 'Menu',
    type: 'array',
    elements: {
      title: { title: 'Name', type: 'text' },
      subTitle: { title: 'Additional Info', type: 'text' },
      image: { title: 'Menu Image', type: 'image' },
      thumb: { title: 'Menu Thumb Image', type: 'image' }
    }
  },
  serviceBackground: { title: 'Service Background Image', type: 'image' },
  serviceTitle: { title: 'Service Title', type: 'text' },
  serviceDescription: { title: 'Service Description', type: 'textarea' },
  galleryBackground: { title: 'Gallery Background Image', type: 'image' },
  galleryTitle: { title: 'Gallery Title', type: 'text' },
  galleryImages: {
    title: 'Gallery',
    type: 'array',
    elements: {
      image: { title: 'Image', type: 'image' },
      thumb: { title: 'Thumb Image', type: 'image' },
      video: { title: 'Youtube Video Embed URL', type: 'text' }
    }
  },
  contactTitle: { title: 'Contact Title', type: 'text' },
  contactBackground: { title: 'Contact background Image', type: 'image' },
  contactEmail: { title: 'Contact Email Address', type: 'text' },
  contactLatLong: { title: 'Contact position on Map Lat, Lng (59.355961,18.134163)', type: 'text' },
  contactPerson: { title: 'Contact Person', type: 'text' },
  facebookAddress: { title: 'Facebook address link', type: 'text' },
  instagramAddress: { title: 'Instagram address link', type: 'text' }
}

const toArray = (structure) => {
  const ret = []
  for (const name in structure) {
    const element = structure[name]
    element.name = name
    const { elements, ...rest } = element
    if (elements) {
      const elementArray = toArray(elements)
      ret.push({ ...rest, elements: elementArray, name })
    } else {
      ret.push({ ...rest, name })
    }
  }
  return ret
}

const config = {
  API_URL: process.env.REACT_APP_API_URL,
  ELEMENTS: toArray(STRUCTURE),
  STRUCTURE
}

export default config
